
import { api } from "@/api/api";
import { ApiGetShortCurriculumDto } from "@/api/generated/Api";
import { authService } from "@/auth/authService";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { CurriculumRouteNames } from "@/shared/enums/RouteNames/curriculumRouteNamesEnum";
import { getForApprovalStatus } from "@/shared/helpers/curriculumHelpers";
import { formatDateShort } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification, runStoreAction } from "@/shared/helpers/store.helpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { VAutocomplete, VSwitch, VTextField } from "vuetify/lib";
import { Store } from "vuex";

export default defineComponent({
  name: "TableStudieplanPage",
  components: {
    BaseLayout,
    BaseTableFiltered,
  },
  setup() {
    const router = useRouter();
    const store = useStore<StoreState>();
    const curriculums = ref<ApiGetShortCurriculumDto[]>();
    const search = ref<string>("");

    const handleEdit = (value: ApiGetShortCurriculumDto) => {
      router.push({
        path: `/studieplaner/studieplan/${value.id}`,
        query: { mainCourseId: value.mainCourseId?.toString() },
      });
    };

    const openCurriculumDetails = (value: ApiGetShortCurriculumDto) => {
      router.push(`/studieplaner/details/${value.id}`);
    };

    onMounted(() => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const statusApproval = await getForApprovalStatus(store);
        if (!statusApproval?.id) {
          openNotification(store, NotificationItemType.Error, "Finner ikke status 'Til godkjenning' i courseStatuses");
          return;
        }
        // TODO API need way to spesify all expect one we don't want 9981
        const response = await api.curriculum.getCurriculaAsync();
        curriculums.value = response.data.filter(
          (current) =>
            current.curriculumStatusId !== statusApproval.id &&
            current.name?.toLowerCase().includes("inaktiv") === false
        );

        if (store.state.ssb.ssbCodes.length === 0) {
          await runStoreAction(store, StoreModules.Ssb, StoreActions.SsbActions.FetchSSBSubject);
        }
      });
    });

    return {
      curriculums,
      openCurriculumDetails,
      handleEdit,
      headers,
      filter: filter(store),
      search,
      CurriculumRouteNames,
      formatDateShort,
    };
  },
});

const filter = (store: Store<StoreState>) => {
  const SSBCodes = store.state.ssb.ssbCodes;
  return [
    {
      component: VTextField,
      value: "hoursMin",
      default: "",
      attrs: {
        label: "Min timer",
        dense: true,
        hideDetails: true,
        clearable: true,
      },
      apply: (value: any, model: any) => {
        // v-text-field's clearable can cause model to equal null
        if (model) {
          return model <= value;
        }
        return true;
      },
    },
    {
      component: VTextField,
      value: "hoursMax",
      default: "",
      attrs: {
        label: "Maks timer",
        dense: true,
        hideDetails: true,
        clearable: true,
      },
      apply: (value: any, model: any) => {
        // v-text-field's clearable can cause model to equal null
        if (model) {
          return model >= value;
        }
        return true;
      },
    },
    {
      component: VAutocomplete,
      value: "ssbCode",
      default: "",
      attrs: {
        label: "SSB code",
        items: SSBCodes,
        dense: true,
        itemText: (item: any) => `${item.ssbCode} - ${item.description}`,
        itemValue: "ssbCode",
        hideDetails: true,
        clearable: true,
      },
      apply: (value: any, model: any) => {
        if (model) {
          return value == model;
        }
        return true;
      },
    },
    {
      component: VSwitch,
      value: "insertedByUsername",
      staticClass: "mx-3",
      default: false,
      attrs: {
        label: "Vis kun mine",
        inset: true,
      },
      apply: (value: any, model: any) => {
        const myUsername = value === authService.user.preferred_username;
        return !model || myUsername;
      },
    },
  ];
};

const headers = [
  { text: "Id", value: "id" },
  { text: "Handlinger", value: "actions", sortable: false },
  { text: "Navn", value: "name" },
  {
    text: "Hovedområde",
    value: "mainCourseName",
    filter: true,
  },
  {
    text: "Kursområde",
    value: "subCourseName",
    filter: true,
  },
  {
    text: "Undervisningsform",
    value: "teachingMethod",
    filter: true,
  },
  {
    text: "Status",
    value: "curriculumStatusName",
    filter: true,
  },
  { text: "Opprettet av", value: "insertedByUsername" },
  { text: "Sist oppdatert", value: "updated" },
];
